import React, { useState, /*useEffect,*/ useRef } from 'react';
//import logo from './logo.svg';
//import './App.css';
import { HashRouter, Routes, Route /*, Link*/ } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import MyAppBar, { drawerWidth } from './common/MyAppBar.jsx';
import ErrorAlert from './common/ErrorAlert.jsx';
import Home from './pages/Home.jsx';
import { ErrorContext, BackdropContext } from './common/constants.js';
import UploadSourceData from './pages/UploadSourceData.jsx';

function App() {
  const errorAlert = useRef(null);
  const infoAlert = useRef(null);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState(null);

  const handleOpenBackdrop = (backdropMessage) => {
    setBackdropMessage(backdropMessage);
    setBackdropOpen(true);
  };
  
  const handleCloseBackdrop = () => {
    setBackdropOpen(false);
    setBackdropMessage(null);
  };

  const setErrorMessage = (message) => {
    if (errorAlert && errorAlert.current) {
      errorAlert.current.setErrorMessagePublic(message);
      if (message && !!message.length) {
        errorAlert.current.setOpenPublic(true);
      } else {
        errorAlert.current.setOpenPublic(false);
      }
    }
  };

  const setInfoMessage = (message) => {
    if (infoAlert && infoAlert.current) {
      infoAlert.current.setErrorMessagePublic(message);
      if (message && !!message.length) {
        infoAlert.current.setOpenPublic(true);
      } else {
        infoAlert.current.setOpenPublic(false);
      }
    }
  };

  return (
  <ErrorContext.Provider value={{ setErrorMessage, setInfoMessage }}>
  <BackdropContext.Provider value={{ handleOpenBackdrop, handleCloseBackdrop }}>
  <HashRouter>
    <CssBaseline />
    <Box sx={{ bgcolor: '#cfe8fc', height: '100vh', p: 2, display: 'flex'  }}>
      <MyAppBar />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/upload-source-data" element={<UploadSourceData />} />
        </Routes>          
      </Box>
      
      <ErrorAlert ref={errorAlert} severity="error" />
      <ErrorAlert ref={infoAlert} severity="info" />
      
      <Backdrop 
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
        onClick={handleCloseBackdrop}
      >
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography variant="h4" component="div">
            {backdropMessage}
          </Typography>
        </Box>
      </Backdrop>      
    </Box>
  </HashRouter>
  </BackdropContext.Provider>
  </ErrorContext.Provider>
  );
}

export default App;
