import React, { /*useState, useEffect, useRef,*/ useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { BACKEND_SCRIPTS_URL, ErrorContext, BackdropContext, parseHttpResponse, displayErrorMessage, fileSettings } from '../common/constants.js';
import MyCard from '../common/MyCard.jsx';

const Input = styled('input')({
  display: 'none',
});

const UploadSourceData = (props) => {
  const errorContext = useContext(ErrorContext);
  const backdropContext = useContext(BackdropContext);

  const uploadFile = (inputFile, fs) => {
    if (!inputFile) {
      return;
    }
    let formData = new FormData();
    if (inputFile && inputFile.files && !!inputFile.files.length) {
      formData.append('file', inputFile.files[0]);
    }
    formData.append('name', fs);
    if (backdropContext) {
      backdropContext.handleOpenBackdrop(`Uploading file to ${fs}.json ...`);
    }
    fetch(BACKEND_SCRIPTS_URL + '/upload-source-data.php', {
      method: "POST", 
      body: formData
    })
    .then(parseHttpResponse)
    .then(json => {
      inputFile.value = null;
      if (backdropContext) {
        backdropContext.handleCloseBackdrop();
      }
      if (json && json.ok && errorContext) {
        errorContext.setInfoMessage(`File ${fs}.json was uploaded to our server successfully.`);
      } 
    })
    .catch(e => {
      inputFile.value = null;
      if (backdropContext) {
        backdropContext.handleCloseBackdrop();
      }
      displayErrorMessage(e, errorContext);
    });
  }

  return (
    <MyCard title="Upload Source Data">
      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start" alignItems="flex-start">
      { fileSettings.map(fs => (
        <label htmlFor={`upload-button-${fs}`} key={fs}>
          <Input accept="application/json" id={`upload-button-${fs}`} type="file" onChange={(e) => { if (e && e.target) { uploadFile(e.target, fs); } }}/>
          <Button variant="contained" component="span">
            Upload {fs}.json
          </Button>
        </label> )) }
      </Box>
    </MyCard>
  );
};

export default UploadSourceData;

