import React, { useState, /*useEffect,*/ forwardRef, useImperativeHandle } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const AlertDialog = forwardRef((props, ref) => {
  const { pre } = props;
  const [ open, setOpen ] = useState(false);
  const [ title, setTitle ] = useState(null);
  const [ text, setText ] = useState(null);
  const [ buttonText, setButtonText ] = useState('OK');
  
  const handleClose = () => {
    setOpen(false);
  };
  
  useImperativeHandle(ref, () => ({
    setOpenPublic: (pOpen) => {
      setOpen(pOpen);
    },
    
    setTitlePublic: (pTitle) => {
      setTitle(pTitle);
    },
    
    setTextPublic: (pText) => {
      setText(pText);
    },
    
    setButtonTextPublic: (pButtonText) => {
      setButtonText(pButtonText);
    },
  }));
  
  return (
    <Dialog 
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        { title }
      </DialogTitle>
      <DialogContent>
        <DialogContentText 
          id="alert-dialog-description" 
          dangerouslySetInnerHTML={{ __html: (pre? `<pre>${text}</pre>` : text) }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>{ buttonText }</Button>
      </DialogActions>      
    </Dialog>    
  );
});

export default AlertDialog;

