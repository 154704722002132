import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const MyLoadingOverlay = (props) => (
  <Box
    color="#fff"
    bgcolor="rgba(0,0,0,0.5)"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="100%"
    {...props}
  >
    <CircularProgress color="inherit" />
  </Box>
);

export default MyLoadingOverlay;

