import React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

const MyCard = ({ title, children, ...rest }) => (
  <Box mt={2} {...rest}>
    <Card variant="outlined">
      <CardHeader title={title} />
      <CardContent>
        { children }
      </CardContent>
    </Card>
  </Box>            
);

export default MyCard;

