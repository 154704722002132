import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
//import { Link } from "react-router-dom";
import { DataGrid, getGridStringOperators } from '@mui/x-data-grid';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import RollbackIcon from '@mui/icons-material/SettingsBackupRestore';
import ImportIcon from '@mui/icons-material/ImportExport';
import FreeImportIcon from '@mui/icons-material/FreeBreakfast';
import FreeRollbackIcon from '@mui/icons-material/LunchDining';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { BACKEND_API_URL, BACKEND_SCRIPTS_URL, BACKEND_API_TOKEN, ErrorContext, BackdropContext, providers, parseHttpResponse, displayErrorMessage,
  TYPE_PROVIDER_INFO_CASHBACK, TYPE_PROVIDER_INFO_VOUCHER, limitString } from '../common/constants.js';
import MyCard from '../common/MyCard.jsx';
import { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import AlertDialog from '../common/AlertDialog';
import SelectSlugDialog from '../common/SelectSlugDialog';
import InputDialog from '../common/InputDialog';
import ConfirmDialog from '../common/ConfirmDialog';
import FreeImportDialog from '../common/FreeImportDialog';
import FreeRollbackDialog from '../common/FreeRollbackDialog';
import MyLoadingOverlay from '../common/MyLoadingOverlay';

const Home = (props) => {

const [loading, setLoading] = useState(false);
const [rows, setRows] = useState([]);
const [page, setPage] = useState(0);
const [pageSize, setPageSize] = useState(50);
const [rowCount, setRowCount] = useState(0);
const [sortModel, setSortModel] = useState([]);
const [filterModel, setFilterModel] = useState({});
const alertDialog = useRef(null);

const errorContext = useContext(ErrorContext);
const backdropContext = useContext(BackdropContext);

const renderProviderInfo = (field, fieldName, merchantNames, typeProviderInfo = TYPE_PROVIDER_INFO_CASHBACK) => {
  let prov = providers.find(p => p.field === fieldName);
  let provLogoUrl = null;
  if (prov) {
    provLogoUrl = `/img/logos/icon-36-${prov.short}.png`;
  } else if (typeProviderInfo === TYPE_PROVIDER_INFO_VOUCHER) {
    let name = fieldName.substring('field_'.length);
    prov = {
      type: 'onCard',
      name: name,
      short: name,
      field: fieldName,
    };
    provLogoUrl = `/img/logos/icon-36-${prov.short}.png`;
  }
  
  const handleRollback = () => {
    let merchant = null;
    if (merchantNames && !!merchantNames.length) {
      merchant = merchantNames.join(",");
      handleRollbackSingle(merchant);
    }
  };
  
  const handleRollbackSingle = (merchant) => {
    let action = null;
    if (fieldName && !!fieldName.length && (typeProviderInfo === TYPE_PROVIDER_INFO_CASHBACK)) {
      switch (fieldName) {
        case 'field_topcashback':
          action = 'rollback_tcb';
          break;
        case 'field_quidco':
          action = 'rollback_quidco';
          break;
        default:
          action = 'rollback_other';
          break;
      }
    } else {
      action = 'rollback_other';
    }
    
    if (backdropContext) {
      backdropContext.handleOpenBackdrop('Rollback ' + fieldName + ' for mechant: ' + merchant);
    }
    fetch(BACKEND_SCRIPTS_URL + '/console-scripts.php', {
        method: 'POST',
        headers: { 
          'Accept': 'application/json', 
          'Content-Type': 'application/json',
        },        
        body: JSON.stringify({ action, merchant }),
      })
      .then(parseHttpResponse)       
      .then(json => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        refreshTblStore();
        if (alertDialog && alertDialog.current) {
          alertDialog.current.setTitlePublic('Results from rollback')
          alertDialog.current.setTextPublic(json.result);
          alertDialog.current.setOpenPublic(true);
        }
      })
      .catch(e => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        displayErrorMessage(e, errorContext);
      });
  };

  
  const handleImport = () => {
    let merchant = null;
    if (merchantNames && !!merchantNames.length) {
      merchant = merchantNames.join(",");
      handleImportSingle(merchant);
    }
  };
  
  const handleImportSingle = (merchant) => {
    let action = null;
    if (fieldName && !!fieldName.length && (typeProviderInfo === TYPE_PROVIDER_INFO_CASHBACK)) {
      switch (fieldName) {
        case 'field_topcashback':
          action = 'import_tcb';
          break;
        case 'field_quidco':
          action = 'import_quidco';
          break;
        default:
          action = 'import_other';
          break;
      }
    } else {
      action = 'import_other';
    }

    if (backdropContext) {
      backdropContext.handleOpenBackdrop('Import ' + fieldName + ' for merchant: ' + merchant);
    }
    fetch(BACKEND_SCRIPTS_URL + '/console-scripts.php', {
        method: 'POST',
        headers: { 
          'Accept': 'application/json', 
          'Content-Type': 'application/json',
        },	
        body: JSON.stringify({ action, merchant }),
      })
      .then(parseHttpResponse) 
      .then(json => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        refreshTblStore();
        if (alertDialog && alertDialog.current) {
          alertDialog.current.setTitlePublic('Results from import');
          alertDialog.current.setTextPublic(json.result);
          alertDialog.current.setOpenPublic(true);
        }
      })
      .catch(e => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        displayErrorMessage(e, errorContext);
      });
  };
  
  return (
  <Box key={`${fieldName}_${field.code}_${field.title}`} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
    <Divider orientation="vertical" variant="fullWidth" flexItem sx={{ borderRightWidth: 5 }} />
    { prov && provLogoUrl && 
      <Tooltip title={prov.name} placement="top">
      <Avatar 
        alt={field.name} 
        src={provLogoUrl}
        sx={{ width: 24, height: 24, padding: 0, ml: 1 }}  
      /> 
      </Tooltip>
    }
    <Box mx={1}>{limitString(field.title)}</Box>
    <Tooltip title="Rollback this merchant import" placement="top">
      <IconButton 
        color="primary" 
        sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
        onClick={handleRollback}
      >
        <RollbackIcon />
      </IconButton>
    </Tooltip>

    <Tooltip title="Import this merchant" placement="top">
      <IconButton 
        color="primary" 
        sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
        onClick={handleImport}
      >
        <ImportIcon />
      </IconButton>
    </Tooltip>
  </Box>
  );
};

const handleCopyTitle = (title) => {
  // copy title to clipboard
  if (navigator && navigator.clipboard) {
    navigator.clipboard.writeText(title);
  }

  if (errorContext) {
    errorContext.setInfoMessage('Title: "' + title + '" copied to clipboard');
  }
};

const generateAllTitlesOfMerchant = (row) => {
  let provFields = providers.map(p => p.field);
  let result = provFields.map(pf => {
    if (row[pf] && row[pf].title) {
      if (row[pf] && row[pf].extra && !!row[pf].extra.length) {
        return row[pf].extra;
      }
    }
    return null;
  }).filter(t => t);
  // insert title as first name
  result.unshift(row.title);
  // remove duplicates
  result = result.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
  return result;
};

const handleCopyAllTitles = (row) => {
  let allNames = generateAllTitlesOfMerchant(row);
  let allNamesString = allNames.join(",");

  // copy all titles to clipboard
  if (navigator && navigator.clipboard) {
    navigator.clipboard.writeText(allNamesString);
  }

  if (errorContext) {
    errorContext.setInfoMessage('ALL names of the selected merchant: "' + row.title + '" copied to clipboard');
  }  
};

const columns = [
  { 
    field: 'id', 
    headerName: 'ID', 
    width: 70, 
    sortable: false, 
    filterable: false, 
  },
  {
    field: 'created',
    type: 'dateTime',
    headerName: 'Created',
    width: 200,
    valueFormatter: ({ value }) => value && new Date(value).toLocaleString('en-GB'),
    filterable: false, 
  },
  { 
    field: 'title', 
    headerName: 'Merchant name', 
    width: 300,
    renderCell: (params) => {
      if (params && params.row && params.row.path && params.row.path.alias) {
        return (
          <>
            <a target="_blank" rel="noreferrer" href={`${BACKEND_API_URL}${params.row.path.alias}`}>{params.value}</a>
            
            <Tooltip title="Copy title to clipboard" placement="top">
              <IconButton
                color="primary"
                sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
                onClick={() => handleCopyTitle(params.value)}
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      }
      return null;
    },     
    filterOperators: getGridStringOperators().filter(operator => operator.value === 'equals' || operator.value === 'contains'),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const confirmDialog = React.createRef();

      const handleDeleteStore = (title) => {
        if (confirmDialog && confirmDialog.current) {
          confirmDialog.current.setTitlePublic('Delete Store');
          confirmDialog.current.setTextPublic(`Are you sure you want to delete store: <strong>${title}</strong>?`);
          confirmDialog.current.setOpenPublic(true);
        }
      }
    
      const handleDeleteStoreImpl = (id, title) => {
        if (!id) {
          return;
        }

      if (backdropContext) {
        backdropContext.handleOpenBackdrop(`Deleting merchant: ${title}`);
      }
      fetch(BACKEND_API_URL + '/jsonapi/node/store/' + id, {
        method: 'DELETE',
        headers: { 
          'Accept': 'application/vnd.api+json', 
          'Content-Type': 'application/vnd.api+json',
          'Authorization': `Basic ${BACKEND_API_TOKEN}`,
        },        
        body: JSON.stringify({}),
      })
      .then(parseHttpResponse)       
      .then(json => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        refreshTblStore();
        if (errorContext) {
          errorContext.setInfoMessage(`Merchant: ${title} was deleted successfully.`);
        }       
      })
      .catch(e => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        displayErrorMessage(e, errorContext);
      });
        
      };
      
      if (params && params.row) {
        return (
          <>
          <Tooltip title="Delete this merchant from database" placement="top">
            <IconButton 
              color="primary" 
              sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
              onClick={() => handleDeleteStore(params.row.title)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Copy ALL names of this merchant to clipboard" placement="top">
            <IconButton
              color="primary"
              sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
              onClick={() => handleCopyAllTitles(params.row)}
            >
              <CopyAllIcon />
            </IconButton>
          </Tooltip>
          
          <ConfirmDialog ref={confirmDialog} onConfirmSubmit={() => { 
            handleDeleteStoreImpl(params.row.id, params.row.title);
          }} />
          </>
        );
      }
      return null;
    },
  }, 
  { 
    field: 'field_slugs', 
    headerName: 'Slugs', 
    width: 600,
    renderCell: (params) => {
      const selectSlugDialog = React.createRef();
      const inputDialog = React.createRef();

      const handleCopySlug = (slugs) => {
        if (slugs && (slugs.length >= 2)) {
          if (selectSlugDialog && selectSlugDialog.current) {
            selectSlugDialog.current.setOperationPublic('Copy');
            selectSlugDialog.current.setSlugsPublic(params.value);
            selectSlugDialog.current.setSelectedSlugPublic('');
            selectSlugDialog.current.setOpenPublic(true);
          }
        } else {
          let slug = slugs[0];
          handleCopySlugSingle(slug);
        }
      };
      
      const handleCopySlugSingle = (slug) => {
        if (navigator && navigator.clipboard) {
          navigator.clipboard.writeText(slug);
        }

        if (errorContext) {
          errorContext.setInfoMessage('Slug: "' + slug + '" copied to clipboard');
        }
      };
      
      const handleAddSlug = () => {
        if (inputDialog && inputDialog.current) {
          inputDialog.current.setTitlePublic('Add new slug');
          inputDialog.current.setTextPublic('Enter the slug you want to add to this merchant.');
          inputDialog.current.setInputLabelPublic('Slug');
          inputDialog.current.setButtonTextPublic('Add');
          inputDialog.current.setInputPublic('');
          inputDialog.current.setOpenPublic(true);
        }
      };
      
      const handleAddSlugImpl = (slug) => {
        let newSlugs = [];
        if (params && params.value && !!params.value.length) {
          newSlugs = [...params.value];
        }
        if (slug && !!slug.length && newSlugs.includes(slug)) {
          if (errorContext) {
            errorContext.setErrorMessage('This slug already belongs to this merchant. Please try another slug!');
          }
          return;
        }
        newSlugs.push(slug);
        
        if (params && params.row && params.row.id) {
        
        let obj = {
          data: {
            type: 'node--store',
            id: params.row.id,
            attributes: {
              field_slugs: newSlugs
            }
          }
        };
      if (backdropContext) {
        backdropContext.handleOpenBackdrop(`Adding slug: ${slug} to merchant: ${params.row.title}`);
      }
      fetch(BACKEND_API_URL + '/jsonapi/node/store/' + params.row.id, {
        method: 'PATCH',
        headers: { 
          'Accept': 'application/vnd.api+json', 
          'Content-Type': 'application/vnd.api+json',
          'Authorization': `Basic ${BACKEND_API_TOKEN}`,
        },        
        body: JSON.stringify(obj),
      })
      .then(parseHttpResponse)       
      .then(json => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        refreshTblStore();
        if (errorContext) {
          errorContext.setInfoMessage(`Slug: ${slug} was added successfully to merchant: ${params.row.title}`);
        }       
      })
      .catch(e => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        displayErrorMessage(e, errorContext);
      });

        }
      };
      
      return (
        <>
          { (params && params.value && !!params.value.length)? params.value.toString() : '' }
            
          { (params && params.value && !!params.value.length) &&
            <Tooltip title="Copy slug to clipboard" placement="top">
              <IconButton
                color="primary"
                sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
                onClick={() => handleCopySlug(params.value)}
              >
                <CopyIcon />
              </IconButton>
            </Tooltip> }

          <Tooltip title="Add slug to this merchant" placement="top">
            <IconButton
              color="primary"
              sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
              onClick={handleAddSlug}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
            
          <SelectSlugDialog 
            ref={selectSlugDialog} 
            onSelectSlug={ (operation, slug) => {
              handleCopySlugSingle(slug);  
            }} /> 
              
          <InputDialog 
            ref={inputDialog}
            onInputSubmit={(input) => {
              handleAddSlugImpl(input);
            }}
          />
        </>
      );
    }, 
    filterOperators: getGridStringOperators().filter(operator => operator.value === 'equals' || operator.value === 'contains'),
  },
  {
    field: 'field_topcashback',
    headerName: 'TCB',
    width: 220,
    renderCell: (params) => {
      if (params && params.value && params.row) {
        let allNames = generateAllTitlesOfMerchant(params.row);
        return renderProviderInfo(params.value, 'field_topcashback', allNames);
      }
      return null;
    },
    sortable: false,
    filterable: false,
  },
  {
    field: 'field_quidco',
    headerName: 'Quidco',
    width: 220,
    renderCell: (params) => {
      if (params && params.value && params.row) {
        let allNames = generateAllTitlesOfMerchant(params.row);
        return renderProviderInfo(params.value, 'field_quidco', allNames);
      }
      return null;
    },
    sortable: false,
    filterable: false,
  },
  {
    field: 'others',
    headerName: 'Others',
    width: 2500,
    renderCell: (params) => {
      if (params && params.row) {
        let provFields = providers.map(p => p.field);
        return (
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        { provFields.map(pf => {
          if ((pf === 'field_topcashback') || (pf === 'field_quidco')) {
            return null;
          }
          if (params.row[pf] && params.row[pf].title) {
            let allNames = generateAllTitlesOfMerchant(params.row);
            return renderProviderInfo(params.row[pf], pf, allNames);
          }
          return null;
        }).filter(f => f) }
        </Box>
        );
      }
      return null;
    },
    sortable: false,
    filterable: false,
  },
  {
    field: 'field_voucher',
    headerName: 'Vouchers',
    width: 2500,
    renderCell: (params) => {
      if (params && params.row && params.value) {
        //let provFields = params.value.map(v => v.provider);
        return (
        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
        { params.value.map(v => {
          let pf = null;
          let prov = providers.find(p => p.short === v.provider);
          if (prov) {
            pf = prov.field;
          } else {
            pf = 'field_' + v.provider;
          }
          
          if (pf && v && v.title) {
            let allNames = generateAllTitlesOfMerchant(params.row);
            return renderProviderInfo(v, pf, allNames, TYPE_PROVIDER_INFO_VOUCHER);
          }
          return null;
        }).filter(f => f) }
        </Box>
        );
      }
      return null;
    },
    sortable: false,
    filterable: false,
  },  
/*  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  }, */
];


/*
const rows = [
  { id: 10, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];
*/

const loadItemsByStore = useCallback(async (store_id) => {
  if (!store_id) {
    return {};
  }

  const apiParams = new DrupalJsonApiParams();
  apiParams.addFilter('status', 1);
  if (store_id) {
    apiParams.addFilter('field_store_reference.id', store_id);
  }
  apiParams.addFields('node--cashback_item', [
    'title', 
    'field_cashback_string',
    'field_cashback_value',
    'field_clicktrough',
//    'field_air_id',
//    'field_domain',
    'field_extra',
    'field_extra2',
    'field_link',
//    'field_merchant_id',
//    'field_merchant_name',
    'field_provider',
//    'field_speedy_awarding',
//    'field_valid_until',
    'field_id',
//    'body',
  ]);

  try {
  let url = BACKEND_API_URL + '/jsonapi/node/cashback_item?' + apiParams.getQueryString({ encode: false }) + '&' + Date.now();
  setLoading(true);

  let response = await fetch(url, {        
    method: 'GET',
    headers: { 
      'Accept': 'application/vnd.api+json', 
      'Content-Type': 'application/vnd.api+json',
      //'Authorization': `Basic ${BACKEND_API_TOKEN}`,
    },        
  });
  let json = await parseHttpResponse(response);
      setLoading(false);
      let newRows = {};
      if (json && json.data) {
        json.data.forEach(item => {
          let providerField = 'field_' + item.attributes.field_provider;
          
          newRows[providerField] = {
                cashback_value: item.attributes.field_cashback_value,
                clicktrough: item.attributes.field_clicktrough,
                extra: item.attributes.field_extra,
                extra2: item.attributes.field_extra2,
                id: item.attributes.field_id,
                title: item.attributes.field_cashback_string,
                uri: item.attributes.field_link? item.attributes.field_link.uri : null,          
          };
        });
      }
      return newRows;
   } catch (e) {
      setLoading(false);
      displayErrorMessage(e, errorContext);
      return {};
    }        
}, [errorContext]);

const loadVouchersByStore = useCallback(async (store_id) => {
  if (!store_id) {
    return [];
  }
  
  const apiParams = new DrupalJsonApiParams();
  apiParams.addFilter('status', 1);
  if (store_id) {
    apiParams.addFilter('field_store_reference.id', store_id);
  }
  apiParams.addFields('node--coupon', [
    'title', 
    'field_coupon_title', 
    'field_code', 
//    'field_cashback_value', 
//    'field_clicktrough', 
    'field_extra', 
//    'field_extra2', 
//    'field_link', 
    'field_provider', 
    'field_valid_until', 
    'field_id', 
//    'body'
  ]);


  try {
  let url = BACKEND_API_URL + '/jsonapi/node/coupon?' + apiParams.getQueryString({ encode: false }) + '&' + Date.now();
  setLoading(true);

  let response = await fetch(url, {        
    method: 'GET',
    headers: { 
      'Accept': 'application/vnd.api+json', 
      'Content-Type': 'application/vnd.api+json',
      //'Authorization': `Basic ${BACKEND_API_TOKEN}`,
    },        
  });
  let json = await parseHttpResponse(response);
      setLoading(false);
      let newRows = [];
      if (json && json.data) {
        newRows = json.data.map(vou => ({
//            uri: vou.attributes.field_link? vou.attributes.field_link.uri : null,
            title: vou.attributes.field_coupon_title,
            code: vou.attributes.field_code,
            date: vou.attributes.field_valid_until, 
            provider: vou.attributes.field_provider,
//            cashback_value: vou.attributes.field_cashback_value,
            id: vou.attributes.field_id,
            extra: vou.attributes.field_extra,
//            extra2: vou.attributes.field_extra2,
//            clicktrough: vou.attributes.field_clicktrough,
//            options: { attributes: { terms: vou.attributes.body? vou.attributes.body.value : null } },
          }));
        
        newRows = newRows.filter(v => (v && v.code && !!v.code.length));
      }
      return newRows;
   } catch (e) {
      setLoading(false);
      displayErrorMessage(e, errorContext);
      return [];
    }
}, [errorContext]);

const refreshTblStore = useCallback(() => {
  const apiParams = new DrupalJsonApiParams();
  apiParams.addPageLimit(pageSize);
  if (page > 0) {
    apiParams.data.page.offset = pageSize * page;
  }
  apiParams.addFilter('status', 1);
  if (filterModel && filterModel.items && !!filterModel.items.length) {
    for (let i=0; i< filterModel.items.length; i++) {
      let mdl = filterModel.items[i];
      if (mdl.columnField && !!mdl.columnField.length &&
          mdl.operatorValue && !!mdl.operatorValue.length &&
          mdl.value && !!mdl.value.length) {
        let operator = null;
        switch (mdl.operatorValue) {
          case 'contains':
            operator = 'CONTAINS';
            break;
          case 'equals':
            operator = '=';
            break;
          default:
            break;    
        }
        
        if (operator) {
          apiParams.addFilter(mdl.columnField, mdl.value, operator);
        } else {
          apiParams.addFilter(mdl.columnField, mdl.value);
        }
      }
    }
  }
  //apiParams.addFilter('field_slugs', 'asdabreakdown', 'CONTAINS');
  let provFields = providers.map(p => p.field);
  apiParams.addFields('node--store', ['drupal_internal__nid', 'path', 'title', 'field_slugs', 'created', 'field_voucher'].concat(provFields));
  if (sortModel && !!sortModel.length) {
    for (let i = 0; i < sortModel.length; i++) {
      let mdl = sortModel[i];
      apiParams.addSort(mdl.field, mdl.sort.toUpperCase());
    }
  } else {
    apiParams.addSort('changed', 'DESC');
    apiParams.addSort('drupal_internal__nid', 'DESC');
  }
  
  let url = BACKEND_API_URL + '/jsonapi/node/store?' + apiParams.getQueryString({ encode: false }) + '&' + Date.now();
  setLoading(true);
  fetch(url, {        
    method: 'GET',
    headers: { 
      'Accept': 'application/vnd.api+json', 
      'Content-Type': 'application/vnd.api+json',
      //'Authorization': `Basic ${BACKEND_API_TOKEN}`,
    },        
  })
    .then(parseHttpResponse)   
    .then(async (json) => {
      setLoading(false);
      if (json && json.meta && json.meta.count) {
        setRowCount(Number(json.meta.count));
      } else {
        setRowCount(0);
      }
      let newRows = [];
      if (json && json.data) {
        newRows = await Promise.all(
          json.data.map(async (item) => 
            Object.assign(
              item.attributes, 
              { 
                id: item.id, 
                field_voucher: await loadVouchersByStore(item.id) 
              },
              await loadItemsByStore(item.id),
            )
          )
        );
      }
      //console.log(newRows);
      setRows(newRows);
    })
    .catch(e => {
      setLoading(false);
      displayErrorMessage(e, errorContext);
    });
}, [page, pageSize, sortModel, filterModel, errorContext, loadVouchersByStore, loadItemsByStore]);

useEffect(() => {
  refreshTblStore();
}, [refreshTblStore]);

// Free Import
  const freeImportDialog = React.createRef();

  const handleFreeImport = () => {
    if (freeImportDialog && freeImportDialog.current) {
      freeImportDialog.current.setInputPublic('');
      freeImportDialog.current.setActionPublic('');
      freeImportDialog.current.setOpenPublic(true);
    }
  }

  const handleFreeImportImpl = (action, merchant) => {
    if (backdropContext) {
      backdropContext.handleOpenBackdrop('Free Import ' + action + ' for merchant: ' + merchant);
    }
    fetch(BACKEND_SCRIPTS_URL + '/console-scripts.php', {
        method: 'POST',
        headers: { 
          'Accept': 'application/json', 
          'Content-Type': 'application/json',
        },	
        body: JSON.stringify({ action, merchant }),
      })
      .then(parseHttpResponse) 
      .then(json => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        refreshTblStore();
        if (alertDialog && alertDialog.current) {
          alertDialog.current.setTitlePublic('Results from free import');
          alertDialog.current.setTextPublic(json.result);
          alertDialog.current.setOpenPublic(true);
        }
      })
      .catch(e => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        displayErrorMessage(e, errorContext);
      });
  };

// Free Rollback
  const freeRollbackDialog = React.createRef();

  const handleFreeRollback = () => {
    if (freeRollbackDialog && freeRollbackDialog.current) {
      freeRollbackDialog.current.setInputPublic('');
      freeRollbackDialog.current.setActionPublic('');
      freeRollbackDialog.current.setOpenPublic(true);
    }
  }

  const handleFreeRollbackImpl = (action, merchant) => {
    if (backdropContext) {
      backdropContext.handleOpenBackdrop('Free Rollback ' + action + ' for merchant: ' + merchant);
    }
    fetch(BACKEND_SCRIPTS_URL + '/console-scripts.php', {
        method: 'POST',
        headers: { 
          'Accept': 'application/json', 
          'Content-Type': 'application/json',
        },	
        body: JSON.stringify({ action, merchant }),
      })
      .then(parseHttpResponse) 
      .then(json => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        refreshTblStore();
        if (alertDialog && alertDialog.current) {
          alertDialog.current.setTitlePublic('Results from free rollback');
          alertDialog.current.setTextPublic(json.result);
          alertDialog.current.setOpenPublic(true);
        }
      })
      .catch(e => {
        if (backdropContext) {
          backdropContext.handleCloseBackdrop();
        }
        displayErrorMessage(e, errorContext);
      });
  };

  return (
    <MyCard title="Home page">
    <ButtonGroup variant="contained" aria-label="Tools button group">
      <Tooltip title="Reload current page in table" placement="top">
        <Button onClick={refreshTblStore}><RefreshIcon /> Refresh</Button>
      </Tooltip>
      <Tooltip title="FREE Import of another merchant" placement="top">
        <Button onClick={handleFreeImport}><FreeImportIcon /> FREE Import</Button>
      </Tooltip>
      <Tooltip title="FREE Rollback of another merchant" placement="top">
        <Button onClick={handleFreeRollback}><FreeRollbackIcon /> FREE Rollback</Button>
      </Tooltip>
    </ButtonGroup>
    <FreeImportDialog ref={freeImportDialog} onInputSubmit={(action, merchant) => {
      handleFreeImportImpl(action, merchant);
    }} />
    <FreeRollbackDialog ref={freeRollbackDialog} onInputSubmit={(action, merchant) => {
      handleFreeRollbackImpl(action, merchant);
    }} />
    
    <div style={{ height: 650, width: '100%' }}>
      <DataGrid
        components={{ LoadingOverlay: MyLoadingOverlay }}
        loading={loading}
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        pagination
        paginationMode="server"
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => { setPageSize(newPageSize); setPage(0); }}
        rowsPerPageOptions={[25, 50, 100]}
        sortingMode="server"
        onSortModelChange={(newSortModel) => { setSortModel(newSortModel); setPage(0); }}
        filterMode="server"
        onFilterModelChange={(newFilterModel) => { setFilterModel(newFilterModel); setPage(0); }}
      />
    </div>
    
    <AlertDialog ref={alertDialog} pre />
    </MyCard>  
  );
};

export default Home;

