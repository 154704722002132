import React from 'react';

export const BACKEND_API_URL = 'https://api.cashbackangel.com';
export const BACKEND_SCRIPTS_URL = 'https://admin.cashbackangel.com/cba-admin-backend'; //'http://localhost:8000';

export const BACKEND_API_TOKEN = 'YnU2aWRvOnNhbW9jc2thMQ=='; // basic authorization

export const ErrorContext = React.createContext();
export const BackdropContext = React.createContext();

export const providers = [
  {
    type: 'cashback',
    name: 'Quidco',
    short: 'quidco',
    field: 'field_quidco',
  },
  {
    type: 'cashback',
    name: 'Topcashback',
    short: 'topcashback',
    field: 'field_topcashback',
  },
  {
    type: 'cashback',
    name: 'Revolut',
    short: 'revolut',
    field: 'field_revolut',
  },
  {
    type: 'cashback',
    name: 'Kidstart',
    short: 'kidstart',
    field: 'field_kidstart',
  },
  {
    type: 'cashback',
    name: 'Rakuten',
    short: 'rakuten',
    field: 'field_rktn',
  },
  {
    type: 'cashback',
    name: 'Widilo',
    short: 'widilo',
    field: 'field_widilo',
  },
  {
    type: 'cashback',
    name: 'Swagbucks',
    short: 'swagbucks',
    field: 'field_swagbux',
  },

  {
    type: 'airMiles',
    name: 'British Airways eStore',
    short: 'british_airways',
    field: 'field_british_airways',
  },
  {
    type: 'airMiles',
    name: 'Virgin Atlantic Shops Away',
    short: 'virgin_atlantic',
    field: 'field_virgin_atlantic_link',
  },
  {
    type: 'airMiles',
    name: 'Aer Lingus',
    short: 'aer_lingus',
    field: 'field_aer_lingus',
  },
  {
    type: 'airMiles',
    name: 'Flying Blue',
    short: 'flying_blue',
    field: 'field_fb_link',
  },
  {
    type: 'airMiles',
    name: 'Vueling',
    short: 'vueling',
    field: 'field_vueling',
  },
  {
    type: 'airMiles',
    name: 'KrisFlyer Spree',
    short: 'krisflyer',
    field: 'field_krisflyer_link',
  },
  {
    type: 'airMiles',
    name: 'Asia Miles',
    short: 'asia',
    field: 'field_asia_miles_link',
  },
  {
    type: 'airMiles',
    name: 'Ana Global Mall',
    short: 'ana',
    field: 'field_ana_link',
  },

  {
    type: 'airMiles',
    name: 'Accor Collections',
    short: 'accor',
    field: 'field_accor_link',
  },
  //{ type: 'airMiles', name: 'Melia Rewards', short: 'melia', field: 'field_melia_link' },
  {
    type: 'airMiles',
    name: 'Wyndham Rewards',
    short: 'wyndham',
    field: 'field_wyndham_link',
  },
  {
    type: 'onCard',
    name: 'Natwest Rewards',
    short: 'natwest',
    field: 'field_natwest',
  },
  {
    type: 'onCard',
    name: 'British Airways on Card',
    short: 'ba_oncard',
    field: 'field_ba_oncard',
  },
  {
    type: 'onCard',
    name: 'Aer Lingus on Card',
    short: 'aer_lingus',
    field: 'field_aer_oncard',
  },
  {
    type: 'onCard',
    name: 'Curve',
    short: 'curve',
    field: 'field_curve',
  },  
];

export const TYPE_PROVIDER_INFO_CASHBACK = 'cashback';
export const TYPE_PROVIDER_INFO_VOUCHER = 'voucher';

// Upload Source Data
export const fileSettings = [ 'natwest', 'revolut' ];

export const parseHttpResponse = (response) => {
  if (response.ok) { 
    if (response.status === 204) {
      return Promise.resolve({});
    }
    return response.json(); 
  } else {
    return response.json().then(json => { throw Object.assign(new Error(), json); });
  }
};

export const displayErrorMessage = (e, ectx) => {
  let message = null;
  if (e && e.message && !!e.message.length) {
    message = e.message;
  }
  if (e && e.errors && !!e.errors.length) {
    message = e.errors.map(err => err.detail).filter(err => err).join(' ');
  }
  if (message && ectx) {
    ectx.setErrorMessage(message);
  }
};

export const LIMIT_CHARACTERS_COUNT = 25;
export const limitString = (str, limit = LIMIT_CHARACTERS_COUNT) => {
  if (str && !!str.length && (str.length > limit)) {
    return str.substring(0, limit) + '...';
  }
  return str;
};

