import React, { useState, /*useEffect,*/ forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

const FreeImportDialog = forwardRef((props, ref) => {
  const { onInputSubmit } = props;
  const [ open, setOpen ] = useState(false);
  const [ input, setInput ] = useState('');
  const [ inputError, setInputError ] = useState(null);
  const [ selectAction, setSelectAction ] = useState('');
  const [ actionError, setActionError ] = useState(null);
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleSubmit = () => {
    // validation
    if (inputError && !!inputError.length) {
      return;
    }
    if (actionError && !!actionError.length) {
      return;
    }
      
    setOpen(false);
    
    if (input && !!input.length && selectAction && !!selectAction.length) {
      if (onInputSubmit) {
        onInputSubmit(selectAction, input);
      }
    }
  };

  const handleChangeInput = (e) => {
    if (e && e.target && e.target.value && !!e.target.value.length) {
      setInput(e.target.value);
      setInputError(null);
    } else {
      setInput('');
      setInputError('This field is required.');
    }
  };

  const handleChangeSelectAction = (e) => {
    if (e && e.target && e.target.value && !!e.target.value.length) {
      setSelectAction(e.target.value);
      setActionError(null);
    } else {
      setSelectAction('');
      setActionError('Provider selection is required.');
    }
  };
      
  useImperativeHandle(ref, () => ({
    setOpenPublic: (pOpen) => {
      setOpen(pOpen);
    },
    
    setInputPublic: (pInput) => {
      if (pInput && !!pInput.length) {
        setInput(pInput);
        setInputError(null);
      } else {
        setInput('');
        setInputError('This field is required.');
      }
    },
    
    setActionPublic: (pAction) => {
      if (pAction && !!pAction.length) {
        setSelectAction(pAction);
        setActionError(null);
      } else {
        setSelectAction('');
        setActionError('Provider selection is required.');
      }
    },
  }));
  
  return (
    <Dialog 
      open={open}
      onClose={handleClose}
      aria-labelledby="free-import-dialog-title"
      aria-describedby="free-import-dialog-description">
      <DialogTitle id="free-import-dialog-title">
        Free import of another merchant
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="free-import-dialog-description">
          Enter the name of the merchant, select a provider and then click the Import button.
        </DialogContentText>
        <Box component="form" noValidate autoComplete="off" mt={2}>
         <TextField
            autoFocus
            fullWidth
            id="input-field"
            label="Merchant name"
            variant="outlined"
            value={input}
            onChange={handleChangeInput}
            error={inputError && !!inputError.length}
            helperText={inputError}
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
          />
          
          <FormControl fullWidth error={actionError && !!actionError.length}>
            <InputLabel id="select-action-label">Provider</InputLabel>
            <Select
              labelId="select-action-label"
              id="select-action"
              value={selectAction}
              label="Provider"
              onChange={handleChangeSelectAction}
              aria-describedby="select-action-helper-text"
            >
              <MenuItem value="import_tcb">
                <ListItemButton>
                  <ListItemIcon>
                    <Avatar 
                      alt="TopCashback" 
                      src="/img/logos/icon-36-topcashback.png"
                      sx={{ width: 24, height: 24, padding: 0, ml: 1 }}  
                    /> 
                  </ListItemIcon>
                  <ListItemText>
                    TopCashback
                  </ListItemText>
                </ListItemButton>
              </MenuItem>
              <MenuItem value="import_quidco">
                <ListItemButton>
                  <ListItemIcon>
                    <Avatar 
                      alt="Quidco" 
                      src="/img/logos/icon-36-quidco.png"
                      sx={{ width: 24, height: 24, padding: 0, ml: 1 }}  
                    /> 
                  </ListItemIcon>
                  <ListItemText>
                    Quidco
                  </ListItemText>
                </ListItemButton>
              </MenuItem>
              <MenuItem value="import_other">
                <ListItemButton>
                  <ListItemIcon />
                  <ListItemText>
                    Others
                  </ListItemText>
                </ListItemButton>
              </MenuItem>
            </Select>
            <FormHelperText id="select-action-helper-text">{actionError}</FormHelperText>
          </FormControl>
        </Box>        
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>Import</Button>
      </DialogActions>      
    </Dialog>    
  );
});

export default FreeImportDialog;

