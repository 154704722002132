import React, { useState, /*useEffect,*/ forwardRef, useImperativeHandle } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ErrorAlert = forwardRef((props, ref) => {
  const { severity } = props;
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  
  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);  
  };
  
  useImperativeHandle(ref, () => ({
    setOpenPublic: (pOpen) => {
      setOpen(pOpen);
    },
    
    setErrorMessagePublic: (pErrorMessage) => {
      setErrorMessage(pErrorMessage);
    },
  }));
  
  return (
    <Snackbar 
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
      open={open} 
      autoHideDuration={9000} 
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={ severity || 'error' } sx={{ width: '100%' }}>
        { errorMessage }
      </Alert>
    </Snackbar>      
  );
});

export default ErrorAlert;

