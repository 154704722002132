import React, { useState, /*useEffect,*/ forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const InputDialog = forwardRef((props, ref) => {
  const { onInputSubmit } = props;
  const [ open, setOpen ] = useState(false);
  const [ title, setTitle ] = useState(null);
  const [ text, setText ] = useState(null);
  const [ inputLabel, setInputLabel ] = useState('Text');
  const [ buttonText, setButtonText ] = useState('Save');
  const [ input, setInput ] = useState('');
  const [ inputError, setInputError ] = useState(null);
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleSubmit = () => {
    // validation
    if (inputError && !!inputError.length) {
      return;
    }
      
    setOpen(false);
    
    if (input && !!input.length) {
      if (onInputSubmit) {
        onInputSubmit(input);
      }
    }
  };

  const handleChangeInput = (e) => {
    if (e && e.target && e.target.value && !!e.target.value.length) {
      setInput(e.target.value);
      setInputError(null);
    } else {
      setInput('');
      setInputError('This field is required.');
    }
  };
    
  useImperativeHandle(ref, () => ({
    setOpenPublic: (pOpen) => {
      setOpen(pOpen);
    },
    
    setTitlePublic: (pTitle) => {
      setTitle(pTitle);
    },
    
    setTextPublic: (pText) => {
      setText(pText);
    },
    
    setInputLabelPublic: (pInputLabel) => {
      setInputLabel(pInputLabel);
    },
    
    setButtonTextPublic: (pButtonText) => {
      setButtonText(pButtonText);
    },
    
    setInputPublic: (pInput) => {
      if (pInput && !!pInput.length) {
        setInput(pInput);
        setInputError(null);
      } else {
        setInput('');
        setInputError('This field is required.');
      }
    },
  }));
  
  return (
    <Dialog 
      open={open}
      onClose={handleClose}
      aria-labelledby="input-dialog-title"
      aria-describedby="input-dialog-description">
      <DialogTitle id="input-dialog-title">
        { title }
      </DialogTitle>
      <DialogContent>
        <DialogContentText 
          id="input-dialog-description"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <Box component="form" noValidate autoComplete="off" mt={2}>
         <TextField
            autoFocus
            fullWidth
            id="input-field"
            label={inputLabel}
            variant="outlined"
            value={input}
            onChange={handleChangeInput}
            error={inputError && !!inputError.length}
            helperText={inputError}
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
          />
        </Box>        
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>{ buttonText }</Button>
      </DialogActions>      
    </Dialog>    
  );
});

export default InputDialog;

