import React, { useState, /*useEffect,*/ forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

const SelectSlugDialog = forwardRef((props, ref) => {
  const { onSelectSlug } = props;
  
  const [ open, setOpen ] = useState(false);
  const [ operation, setOperation ] = useState(null);
  const [ slugs, setSlugs ] = useState([]);
  const [ selectedSlug, setSelectedSlug ] = useState('');
  const [ slugError, setSlugError ] = useState(null);
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const handleSelectSlug = () => {
    // validation
    if (slugError && !!slugError.length) {
      return;
    }
    
    setOpen(false);
    
    if (onSelectSlug) {
      onSelectSlug(operation, selectedSlug);
    }
  };  

  const handleChangeSelectedSlug = (e) => {
    if (e && e.target && e.target.value && !!e.target.value.length) {
      setSelectedSlug(e.target.value);
      setSlugError(null);
    } else {
      setSelectedSlug('');
      setSlugError('Slug selection is required.');
    }
  };
    
  useImperativeHandle(ref, () => ({
    setOpenPublic: (pOpen) => {
      setOpen(pOpen);
    },
    
    setOperationPublic: (pOperation) => {
      setOperation(pOperation);
    },
    
    setSlugsPublic: (pSlugs) => {
      setSlugs(pSlugs);
    },
    
    setSelectedSlugPublic: (pSelectedSlug) => {
      if (pSelectedSlug && !!pSelectedSlug.length) {
        setSelectedSlug(pSelectedSlug);
        setSlugError(null);
      } else {
        setSelectedSlug('');
        setSlugError('Slug selection is required.');
      }
    },
  }));
  
  return (
    <Dialog 
      open={open}
      onClose={handleClose}
      aria-labelledby="select-slug-dialog-title"
      aria-describedby="select-slug-dialog-description">
      <DialogTitle id="select-slug-dialog-title">
        Select Slug for {operation} operation
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off" mt={2}>
        <FormControl fullWidth variant="outlined" error={slugError && !!slugError.length}>
          <InputLabel id="select-slug-label">Slug</InputLabel>
          <Select
            id="select-slug-input"
            labelId="select-slug-label"
            label="Slug"
            value={selectedSlug}
            onChange={handleChangeSelectedSlug}
            aria-describedby="select-slug-helper-text"
          >
            { slugs.map(s =>
              <MenuItem key={s} value={s}>{s}</MenuItem>
            )}
          </Select>
          <FormHelperText id="select-slug-helper-text">{slugError}</FormHelperText>
        </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSelectSlug}>Select</Button>
      </DialogActions>      
    </Dialog>       
  );
});

export default SelectSlugDialog;

